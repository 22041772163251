import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Main from '../components/Contacto/main'

const IndexPage = () => (
  <Layout>
    <Seo title="Contacto" description="Aquí debería ir la descripción de la sección de contacto" />
    <Main />
  </Layout>
)

export default IndexPage
