import React from 'react';
import Breadcrumbs from "../breadcrumbs";
import Formulario from '../Contact'
import { graphql, useStaticQuery } from 'gatsby';

const Main = () => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              keys {
                captcha
              }
              social{
                facebook
                instagram
                twitter
                youtube
                linkedin
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                    }
                    footer {
                        title_contact
                        title_social
                        title_newsletter
                        title_office
                        button_contact{
                            link
                            value
                        }
                    }
              }
          }
    }`)

    return(
        <section className="contact-main">
            <div className="container-fluid">
                <div className="row height">
                    <div className="col-12 d-flex align-items-center">
                        <div className="row mt-4 pt-lg-0 pt-5 content-main">
                            <div className="col-12">
                                <Breadcrumbs
                                    props={[
                                    { name: realEstate.name, route: "/", location: "" },
                                    { name: "Contacto", route:   "", location: ""},
                                    ]}
                                />
                            </div>
                            <div className="col-12">
                                <h1>CONTACTO</h1>
                            </div>
                            <div className="col-lg-7 order-2 order-lg-1">
                                <Formulario from={'contact'} />
                            </div>
                            <div className="col-lg-3 offset-lg-1 text-center contact-nav d-lg-flex d-none align-items-center order-1 order-lg-2">
                                <div className="center-contact">
                                    <div class="item-contact">
                                        <h5>{realEstate.sections.footer.title_contact}</h5>
                                        <a class="hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
                                        {realEstate.global_phone.map(phone => (
                                            <p >Teléfono {phone}</p>
                                        ))}
                                    </div>
                                    <div className="max-heigth-scroll">
                                        {realEstate.branch_office.map((item,index) => (
                                            <div class="item-contact">
                                                <h5>{item.region}</h5>
                                                <p >{item.name}</p>
                                                <p >{item.address}</p>
                                                <p >{item.city}</p>
                                                {/* <p >{item.region}</p> */}
                                                {item.contact.phone.map(phone => (
                                                    <p >Teléfono {phone}</p>
                                                ))}
                                                {/* <p className={item.contact.whatsapp === '' && 'd-none'}>Whatsapp +{ item.contact.whatsapp }</p>
                                                <p className={item.broker_id === '' && 'd-none'}>Matrícula {item.broker_id}</p> */}
                                            </div>
                                        ))} 
                                    </div>
                                    <div class="item-contact">
                                        <h5 className='mb-3'>{realEstate.sections.footer.title_social}</h5> 
                                        <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class="icon-instagram"></i></a>
                                        <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class="icon-facebook"></i></a>
                                        <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class="icon-linkedin"></i></a>
                                        <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class="icon-youtube"></i></a>
                                        <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class="icon-twitter"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
} 

export default Main;